/** @jsx jsx */
import { jsx } from "theme-ui";

import { IndexWithRouter } from "@heartfulnessinstitute/react-hfn-forms/dist/ui/CrudTemplate";
import { selectorFirebaseAuthProfile } from "gatsby-plugin-hfn-profile/state/selectors";
import { useSelector } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import PropTypes from "prop-types";
import Config from "./config1";

function Main(props) {
  const { uri } = props;
  const auth = useSelector(selectorFirebaseAuthProfile);
  const authLoaded = isLoaded(auth);

  return (
    <div style={{ padding: 10 }}>
      {authLoaded && (
        <IndexWithRouter Config={{ ...Config, homeUrl: () => uri }} />
      )}
    </div>
  );
}

Main.defaultProps = {
  uri: "",
};

Main.propTypes = {
  uri: PropTypes.string,
};

export default Main;
