import get from "lodash/get";

export const bldgConfig = {
  fields: [
    { name: "slug", label: "Slug" },
    { name: "title", label: "Title" },
    {
      name: "content",
      label: "Page Content (html)",
      props: { multiline: true, required: false },
    },
  ],

  defaults: {},
  collection: "FirestorePages",
  primaryKey: "slug",
};

export const postprocessBldg = (newd) => {
  const newdata = JSON.parse(JSON.stringify(newd));
  return newdata;
};

export const queryBldgs = () => ({
  collection: bldgConfig.collection,
});

export const selectorBldgsId = (state, docId) =>
  get(state.firestore.data, [bldgConfig.collection, docId], undefined);

export const selectorBldgsIdList = (state) =>
  bldgConfig.collection in state.firestore.ordered
    ? state.firestore.ordered[bldgConfig.collection]
    : [];

export const selectorBldgsComplexesList = () => [
  { key: "", text: "All Pages" },
];
