import React from "react";
import {
  queryBldgs,
  bldgConfig,
  selectorBldgsId,
  postprocessBldg,
  selectorBldgsIdList,
  selectorBldgsComplexesList,
} from "./sample-db";

const Config = {
  primaryKey: bldgConfig.primaryKey,

  initialQueries: [queryBldgs()],

  backUrl: null,

  sessionKey: "pages.rooms",
  noItemMsg: "No Pages Found",
  mainTitle: "Firestore Pages",

  pageOptions: {
    Area: {
      optionsSelector: selectorBldgsComplexesList,
      pageSelector: selectorBldgsIdList,
    },
  },

  singleItemSelector: selectorBldgsId,
  singleItemDispatcher: null,

  collection: bldgConfig.collection,
  postprocess: postprocessBldg,
  formFields: bldgConfig.fields,
  formDefaults: bldgConfig.defaults,

  colDefns: [
    ["Slug", "slug"],
    ["Title", "title"],
    [
      "Preview",
      (item) => (
        <div>
          Preview{" "}
          <a
            href={`/preview?f-page=${item.slug}`}
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            {item.slug}
          </a>
        </div>
      ),
    ],
    [
      "Published Page Link",
      (item) => (
        <div>
          <a href={`/${item.slug}`} target="_blank" rel="noreferrer">
            {" "}
            /{item.slug}
          </a>
        </div>
      ),
    ],
  ],
  tableEditButtonProps: { text: "Edit" },
  editBtnText: "Edit",
  newBtnText: "New",
  displayAsTable: true,
  showTableHeader: true,
};

export default Config;
